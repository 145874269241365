export function csvToJson(text: string, quoteChar = '"', delimiter = ',') {
    var rows = text.split('\n')
    var headers = rows[0].split(',')

    const regex = new RegExp(`\\s*(${quoteChar})?(.*?)\\1\\s*(?:${delimiter}|$)`, 'gs')

    const match = (line) => [...line.matchAll(regex)].map((m) => m[2]).slice(0, -1)

    var lines = text.split('\n')
    const heads = headers ?? match(lines.shift())
    lines = lines.slice(1)
    if (!lines[lines.length - 1]) lines.pop()

    return lines.map((line) => {
        return match(line).reduce((acc, cur, i) => {
            // replace blank matches with `null`
            const val = cur.length <= 0 ? null : Number(cur) || cur
            const key = heads[i] ?? `{i}`
            return { ...acc, [key.trim()]: val }
        }, {})
    })
}

export const downloadToCSV = (
    cellKeys: string[],
    cellHeadings: string[],
    data: any[],
    fileName: string,
    config: { addSerialNumber: boolean },
) => {
    let csvContent = ''
    let srno = 1

    let headingsRow = [...(config?.addSerialNumber ? ['S.no'] : []), ...cellHeadings].join(',')
    csvContent += headingsRow + '\r\n'

    data.forEach((each) => {
        const rowData = [...(config?.addSerialNumber ? [srno] : []), ...cellKeys.map((key) => each[key])]
        srno++
        let row = rowData.join('`')
        row = row.replaceAll(',', ' ')
        row = row.replaceAll('`', ',')
        csvContent += row + '\r\n'
    })

    var pom = document.createElement('a')
    var blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
    var url = URL.createObjectURL(blob)
    pom.href = url
    pom.setAttribute('download', `${fileName}.csv`)
    pom.click()
}
