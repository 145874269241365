import dayjs from 'dayjs'

interface Config {}

export const convertIsoToLocaleString = (dateString: string) => {
    if (!dateString) return ''
    const short = Intl.DateTimeFormat(undefined, {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour12: false,
        hour: '2-digit',
        minute: '2-digit',
    })
    return short.format(new Date(dateString)).replace(',', '')
}

export const convertIsoToLocaleDateString = (dateString: string) => {
    if (!dateString) return ''
    const short = Intl.DateTimeFormat(undefined, {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
    })
    return short.format(new Date(dateString))
}

export const filterDateFormatter = (date: Date | string | dayjs.Dayjs) => {
    if (date) return dayjs(date).format('YYYY-MM-DD')
}
