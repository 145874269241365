import { createStore } from 'zustand/vanilla'

interface FilterState {
    filters: {}
    setFilters: (filterData: {}) => void
}

export const filterStore = createStore<FilterState>((set) => ({
    filters: {
        dashboard: {},
        checkout: { orders: {} },
        payment: { refunds: {}, bulkRefunds: {}, transactions: {} },
        checkout_platform: {},
        ellora: {},
        shopify_app: {},
    },
    setFilters: (filterData: { key: string; data: {} }) =>
        set((state) => ({
            ...state,
            filters: {
                ...state.filters,
                [filterData.key]: {
                    ...(state[filterData.key] || {}),
                    ...filterData.data,
                },
            },
        })),
}))
