//Dispatches a custom 'loader' event with the provided data.
//This event can be used to indicate the state of a loader or loading spinner.
interface LoaderEventData {
  count: number;
  text?: string;
}
export const loaderEvent = (data: LoaderEventData) => {
  const customEvent = new CustomEvent('loader', {
    detail: {
      data,
    },
  });
  window.dispatchEvent(customEvent);
};

//Dispatches a custom 'timerReset' event with the provided data.
//This event can be used to signal the reset of a timer or countdown.
export const timerEvent = (data: any) => {
  const customEvent = new CustomEvent('timerReset', {
    detail: {
      data,
    },
  });
  window.dispatchEvent(customEvent);
};
