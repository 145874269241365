import { createStore } from 'zustand/vanilla'

export const dashboardStore = createStore<{
    userData: {
        user_details: any
        config: any
        merchant_details: any
        mode: string
    }
    setUserData: (userData: any) => void
    setMode: (mode: string) => void
    emptyUserData: () => void
}>((set) => ({
    userData: { user_details: {}, config: {}, merchant_details: {}, mode: '' },
    setUserData: (userData) => set((prev) => ({ ...prev, userData: { ...prev.userData, ...userData } })),
    setMode: (mode: string) => set((prev) => ({ ...prev, userData: { ...prev.userData, mode } })),
    emptyUserData: () =>
        set((prev) => ({
            ...prev,
            userData: { user_details: {}, config: {}, merchant_details: {}, mode: prev.userData.mode },
        })),
}))

export const appRoutes = createStore<{
    appRoutes: any[]
    addAppRoute: (data: any) => void
    setAppRoutes: (data: any) => void
    updateAppRoutes: (callback: (appRoutes: any[]) => any) => void
}>((set) => ({
    appRoutes: [],
    addAppRoute: (newRoute) => set((prev) => ({ ...prev, appRoutes: [...prev.appRoutes, newRoute] })),
    setAppRoutes: (appRoutes) => set((prev) => ({ ...prev, appRoutes })),
    updateAppRoutes: (callbackFn) => set((prev) => ({ ...prev, appRoutes: callbackFn(prev.appRoutes) })),
}))

const {
    addAppRoute: addBreadcrumb,
    setAppRoutes: setBreadcrumb,
    appRoutes: breadcrumbsData,
    updateAppRoutes: updateBreadcrumbs,
} = appRoutes.getState()

const { setUserData, userData, setMode, emptyUserData } = dashboardStore.getState()
export { addBreadcrumb, setBreadcrumb, breadcrumbsData, updateBreadcrumbs, setUserData, setMode, emptyUserData }
