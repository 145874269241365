interface Result {
    isEqual: boolean
    diff: Record<string, unknown>
}

export const deepEqualAndDiff = (oldData: Record<string, unknown>, newData: Record<string, unknown>): Result => {
    let result: Result = { isEqual: true, diff: {} }

    if (oldData === newData) return result

    if (typeof oldData !== 'object' || oldData === null || typeof newData !== 'object' || newData === null) {
        result.isEqual = false
        return result
    }

    let keysOld = Object.keys(oldData)
    let keysNew = Object.keys(newData)

    if (keysOld.length !== keysNew.length) result.isEqual = false

    for (let key of keysOld) {
        if (
            !keysNew.includes(key) ||
            !deepEqualAndDiff(oldData[key] as Record<string, unknown>, newData[key] as Record<string, unknown>).isEqual
        ) {
            result.isEqual = false
            result.diff[key] = newData[key]
        }
    }

    return result
}
