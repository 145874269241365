import { navigateToUrl } from 'single-spa'
import axios, { AxiosRequestConfig, ResponseType } from 'axios'
import { loaderEvent, timerEvent } from '../utils'
import { dashboardStore } from '../stores'

//Represents a configuration object for making API calls.
interface APIRequestConfig {
    method: 'get' | 'post' | 'put' | 'patch' | 'delete'
    url: string
    payload?: any
    params?: any
    headers?: any
    isUpload?: boolean
    file?: string | Blob
    skipLoader?: boolean
    skipMode?: boolean
    responseType?: ResponseType
    skipCookies?: boolean
}
const internalUserPostUrls = [
    '/api/reports',
    '/events',
    '/team/switch-merchant',
    '/user/switch-dashboard',
    '/shopify/table',
    '/kwikchat/orders',
    '/customer-verification',
    '/external-event/payload',
    '/event2/payload',
]

/**
 * Makes an API call using Axios with the provided configuration.
 * @param apiConfig - Configuration object for the API call.
 * @returns Promise that resolves with the API response or rejects with an error.
 */
export async function makeAPICall(apiConfig: APIRequestConfig) {
    // Create an Axios instance with the specified base URL and headers.
    const userData = dashboardStore.getState().userData.user_details

    if (
        userData?.role?.name === 'internal user' &&
        apiConfig.method !== 'get' &&
        !internalUserPostUrls.find((url) => apiConfig.url.includes(url))
    ) {
        return { error: true, message: 'Internal user cannot perform this operation' }
    }
    const instance = axios.create({
        baseURL: process.env.REACT_APP_BASE_URL,
        headers: {
            'X-Content-Type-Options': 'nosniff',
            'Referrer-Policy': 'strict-origin-when-cross-origin',
            'X-XSS-Protection': '1; mode=block',
            'Content-Security-Policy': `frame-ancestors "self"`,
            ...(process.env.NODE_ENV !== 'development' && {
                'Strict-Transport-Security': 'max-age=31536000 ; includeSubDomains',
            }),
            // Authorization: localStorage.getItem('token'),
            ...(localStorage.getItem('merchant-mid') && { 'merchant-mid': localStorage.getItem('merchant-mid') }),
        },
        ...(!apiConfig?.skipCookies && { withCredentials: true }),
        ...(apiConfig?.responseType ? { responseType: apiConfig.responseType } : {}),
    })
    try {
        // Increment the loader count to indicate a loading state.
        !apiConfig.skipLoader && loaderEvent({ count: 1 })
        const params = {
            ...(apiConfig.params || {}),
            ...(!apiConfig.skipMode &&
                userData &&
                !userData.is_internal_user && { mode: localStorage.getItem('mode') }),
        }
        // Handle file upload separately
        if (apiConfig.isUpload) {
            const formData = new FormData()
            formData.append('image', apiConfig.file)

            const uploadConfig: AxiosRequestConfig = {
                params,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    ...apiConfig.headers,
                },
            }

            // Perform the file upload API call and get the response.
            const uploadResponse = await instance.post(apiConfig.url, formData, uploadConfig)

            // Decrement the loader count to remove the loading state.
            !apiConfig.skipLoader && loaderEvent({ count: -1 })

            // Return the file upload response with a success flag.
            return { ...uploadResponse, success: true }
        }
        // Prepare the Axios request config based on the method and payload.
        const axiosConfig: AxiosRequestConfig = {
            method: apiConfig.method,
            params,
            headers: apiConfig.headers,
            url: apiConfig.url,
            ...(apiConfig.method !== 'get' && { data: { ...apiConfig.payload } }), // Use 'data' property for non-'GET' methods.
        }
        // Perform the API call and get the response.
        const response = await instance(axiosConfig)
        // Decrement the loader count to remove the loading state.
        !apiConfig.skipLoader && loaderEvent({ count: -1 })
        // Reset the timer (if used for timeout handling).
        timerEvent(null)
        // Return the API response with a success flag.
        return { ...response, success: true }
    } catch (error) {
        !apiConfig.skipLoader && loaderEvent({ count: -1 })
        // Handle specific error cases, like unauthorized (401).
        if (error?.response?.data?.status_code === 401) {
            if (
                ![
                    '/signup',
                    '/login',
                    '/forgot-password',
                    '/change-password',
                    '/verify-otp',
                    '/verify-merchant',
                    '/set-password',
                    '/existing-merchant',
                    '/invitation',
                ].includes(window.location.pathname)
            ) {
                sessionStorage.setItem('redirectTo', window.location.pathname)
            }
            // Clear the token and navigate to the login page if unauthorized.
            localStorage.removeItem('token')
            navigateToUrl('/login')
        }
        // Log the error and return the error object with an error flag.
        console.log(error)
        return { ...error, error: true }
    }
}

interface EventsInterface {
    url: string
    event_type?: string
    event_name?: string
    screen_name?: string
    request_id: string
    session_id: string
    merchant_id: string
    merchant_name?: string | null
    username?: string | null
    timestamp?: any | null
    data?: any | null
}

export async function logUserEvent(eventsConfig: EventsInterface) {
    const userAgentString = navigator.userAgent

    //common function where we can add some fixed params which we want to pass in every event.
    function getFixedParams() {
        const trackingId = sessionStorage.getItem('tracking_id')
        const params = {}

        if (trackingId) {
            params['tracking_id'] = trackingId
        }

        return params
    }

    function parseUserAgent(userAgentString) {
        const result = {
            user_agent: userAgentString,
            os: '',
            device: '',
            os_version: '',
            browser: '',
            browser_version: '',
            is_wv: userAgentString.includes('wv'),
        }

        // Parsing operating system
        if (userAgentString.includes('Windows')) {
            result.os = 'Windows'
            result.os_version = getVersion(userAgentString, 'Windows')
        } else if (userAgentString.includes('Macintosh')) {
            result.os = 'Macintosh'
        } else if (userAgentString.includes('Linux')) {
            result.os = 'Linux'
        } else if (userAgentString.includes('Android')) {
            result.os = 'Android'
            result.os_version = getVersion(userAgentString, 'Android')
            result.device = getDevice(userAgentString)
        } else if (userAgentString.includes('iOS')) {
            result.os = 'iOS'
            result.os_version = getVersion(userAgentString, 'OS')
            result.device = getDevice(userAgentString)
        } else {
            result.os = 'Other'
        }

        // Parsing browser
        if (userAgentString.includes('Chrome')) {
            result.browser = 'Chrome'
        } else if (userAgentString.includes('Firefox')) {
            result.browser = 'Firefox'
        } else if (userAgentString.includes('Safari')) {
            result.browser = 'Safari'
        } else if (userAgentString.includes('Edge')) {
            result.browser = 'Edge'
        } else if (userAgentString.includes('MSIE') || userAgentString.includes('Trident/')) {
            result.browser = 'Internet Explorer'
        } else {
            result.browser = 'Other'
        }

        // Extracting browser version
        const match = userAgentString.match(/(Chrome|Firefox|Safari|Edge|MSIE|rv(?::| ))\/([\d.]+)/)
        if (match && match.length >= 3) {
            result.browser_version = match[2]
        }

        return result
    }

    function getVersion(userAgentString, target) {
        const match = userAgentString.match(new RegExp(`${target}\\s(\\d+(?:[._]\\d+)*)`))
        if (match && match.length > 1) {
            return match[1].replace(/_/g, '.')
        }
        return ''
    }

    function getDevice(userAgentString) {
        if (userAgentString.includes('iPhone')) {
            return 'iPhone'
        } else if (userAgentString.includes('iPad')) {
            return 'iPad'
        } else if (userAgentString.includes('Android')) {
            return 'Android'
        } else {
            return 'Unknown'
        }
    }

    const parsedUserAgentInfo = parseUserAgent(userAgentString)
    const fixedParams = getFixedParams()

    try {
        const response = await axios.post(
            eventsConfig?.url,
            {
                event_type: eventsConfig?.event_type,
                event_name: eventsConfig?.event_name,
                landing_page: eventsConfig?.screen_name,
                request_id: eventsConfig?.request_id,
                session_id: eventsConfig?.session_id,
                merchant_id: eventsConfig?.merchant_id,
                merchant_name: eventsConfig?.merchant_name,
                username: eventsConfig?.username,
                timestamp: eventsConfig?.timestamp,
                data: eventsConfig?.data,
                ...parsedUserAgentInfo,
                ...fixedParams,
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        )
        return response
    } catch (error) {
        console.error('Error:', error)
    }
}
