/**
 * Utility function to get a cookie by name
 * @param {string} name - The name of the cookie to retrieve
 * @returns {string | null} - The value of the cookie, or null if not found
 */
export const getCookie = (name: string): string | null => {
    const nameEQ = `${encodeURIComponent(name)}=`
    const cookies = document.cookie.split(';').map((cookie) => cookie.trim())

    for (let cookie of cookies) {
        if (cookie.startsWith(nameEQ)) {
            return decodeURIComponent(cookie.substring(nameEQ.length))
        }
    }

    return null
}

// Usage example
const token = getCookie('token')
console.log(token) // REMOVE THIS - This will log the value of the 'token' cookie or null if not found
